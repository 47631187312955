import React from 'react';
import classes from './GalleryItem.module.scss';

import { Card, CardImg, Col } from 'reactstrap';

const galleryItem = (props) => {
    return (
        <Col className={`${classes.SlideContainer} my-2 ps-0 col col-sm-6`}>
            <Card inverse className={`${classes.Slide}`}>
                <CardImg src={props.src} alt={props.alt} />
            </Card>
        </Col>
    );
};

export default galleryItem;
