import React, {useEffect, useState} from 'react';
import Aux from '../../hoc/Aux/Aux';
import {checkValidity} from '../../shared/utility';
import Input from '../../components/UI/Input/Input';
import ReCaptcha from 'reaptcha';
import {Alert, Button, Col, FormText} from 'reactstrap';
import axios from '../../axios-base';
import Cookies from "js-cookie";
import {useHistory, useParams} from 'react-router-dom';

function SMSVote(props) {
    const [votingButton, setVotingButton] = useState('Initialisiere...');
    const [votingReady, setVotingReady] = useState(false);
    const [captchaLoaded, setCaptchaLoaded] = useState(false);
    const [SMSCodeConfig, setSMSCodeConfig ] = useState({
        elementType: 'input',
        elementConfig: {
            type: 'text',
            inputMode: "text",
            placeholder: 'Beispiel: PKX8QKZ8',
            label: 'Ihr SMS-Code zur Stimmabgabe',
            columns: 12,
            disabled: false
        },
        value: '',
        validation: {
            required: true,
            isSMSCode: true,
            maxLength: 8,
            minLength: 8
        },
        valid: false,
        errorMessage: 'Bitte geben Sie eine gültige SMS Code an',
        touched: false,
        ifAuth: false
    });

    let captcha = null;
    let history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        if(props.projectCategory){
            Cookies.set('bigfoot_redirect-category', props.projectCategory , {expires: 180});
        }
    }, [props.projectCategory]);


    const navigateToPage = () => {
        history.push('/code-anfordern/');
    };

    const onChangeHandler = event => {
        const userInput = event.target.value.toUpperCase();

        const updatedSMSCode = {
            ...SMSCodeConfig
        };

        updatedSMSCode.value = userInput;
        updatedSMSCode.valid = checkValidity(updatedSMSCode.value, updatedSMSCode.validation)
        updatedSMSCode.touched = true;

        let updatedVotingReady = updatedSMSCode.valid && captchaLoaded;
        console.log("updatedSMSCode ***: ", updatedSMSCode);
        setSMSCodeConfig(updatedSMSCode);
        setVotingReady(updatedVotingReady);
    };

    const onVoteHandler = () => {
        console.log("on vote handler is called...")
        captcha.execute();
    };

    const onVerify = (responseToken) => {
        console.log("on verify is called ...");

        axios.patch('/sms', {
            'responseToken': responseToken,
            'code': SMSCodeConfig.value,
            'projectId': id
        })
            .then((response) => {
                console.log("Response: ", response)
                if (response !== undefined) {
                    console.log("successful response")
                    let updatedSMSCode = {...SMSCodeConfig};
                    updatedSMSCode.elementConfig.disabled = true;
                    setVotingReady(false);
                    setVotingButton('Sie haben bereits abgestimmt');
                    setSMSCodeConfig(updatedSMSCode);

                    Cookies.remove('bigfoot_code-requested');
                    history.push('/stimme-bestaetigt')
                } else {
                    console.log("already voted response")
                    setVotingReady(false);
                    setVotingButton('Sie haben bereits abgestimmt!');
                }
            })
            .catch(error => {
                console.log(error);
                setVotingReady(false);
            });
        captcha.reset();
    };

    const onLoad = () => {
        setVotingButton('Jetzt per SMS abstimmen!'); // Vote by SMS
        setCaptchaLoaded(true);
    };

    const onError = () => {
        console.log('Es ist ein Fehler beim Verifizieren des reCAPTCHAS aufgetreten!');
        setVotingButton('Verifiziere reCAPTCHA...');
    };

    let form = (
        <Aux>
            <Input
                wrapperCSSClass="px-0 mb-0"
                type={SMSCodeConfig.elementType}
                name="SMSCode"
                id="SMSCode"
                placeholder={SMSCodeConfig.elementConfig.placeholder}
                elementType={SMSCodeConfig.elementType}
                elementConfig={SMSCodeConfig.elementConfig}
                value={SMSCodeConfig.value}
                changed={(event) => onChangeHandler(event)}
                shouldValidate={SMSCodeConfig.validation}
                touched={SMSCodeConfig.touched}
                errorMessage={SMSCodeConfig.errorMessage}
                invalid={!SMSCodeConfig.valid}
                formText={SMSCodeConfig.formText} />
            <FormText>Bitte Ihren 8-stelligen SMS-Code eingeben. <span style={{cursor: 'pointer'}} className="text-primary anl_sms--redirect-request" role="button" onClick={() => navigateToPage()}>Noch keinen Code?</span></FormText>
        </Aux>
    );

    return(
        <Col xs="12" lg="12">
            <Alert color="primary">
                {Cookies.get('bigfoot_cookie-consent') === 'true' &&
                <ReCaptcha
                    ref={e => (captcha = e)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                    onVerify={onVerify}
                    onError={onError}
                    onLoad={onLoad}
                    size="invisible"
                />}
                {form}
                <Button
                    className={votingReady ? 'anl_sms--vote mt-3' : 'mt-3'}
                    block
                    disabled={!votingReady}
                    color="primary"
                    onClick={() => onVoteHandler()}
                >
                    {votingButton}
                </Button>
                <p className="text-black-50 small mt-2 mb-0">Diese Seite wird durch reCAPTCHA geschützt.
                    Es gelten die <a className="anl_recaptcha--privacy" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Daten&shy;schutz&shy;erklärung</a> und die <a className="anl_recaptcha--terms" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Nutzungs&shy;bedingungen</a> von Google.</p>
            </Alert>
        </Col>
    );

}

export default SMSVote;
